@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Rubik Light Light'), local('Rubik-Light-Light'),
    url('./fonts/rubik-v11-latin-300.woff2') format('woff2');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Raleway Thin Regular'), local('Raleway-Thin-Regular'),
    url('./fonts/raleway-v18-latin-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Raleway Thin Italic'), local('Raleway-Thin-Regular'),
    url('./fonts/raleway-v18-latin-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Raleway Thin SemiBold'), local('Raleway-Thin-SemiBold'),
    url('./fonts/raleway-v18-latin-600.woff2') format('woff2');
}
